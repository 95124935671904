import { update, get } from "idb-keyval";
import { IRecentlyOpenedEntry } from "../Components/LandingPage/RecentFilesDropdown";
import useStore from "../Store/Store";
import { buildRepoDataSnapshot } from "./RepoDataAction";

export const storeOpenProjectMetadataInCodeCanvasIndexedDB = async () => {
  const directoryHandle: FileSystemDirectoryHandle =
    useStore.getState().session.local.directory.handle;

  const sessionMode = useStore.getState().session.mode;
  let storageType = sessionMode === "local" ? "local" : "github";

  if (directoryHandle) {
    await update(`recentlyOpened`, (recentlyOpened) => {
      if (!recentlyOpened) {
        recentlyOpened = {};
      }
      if (directoryHandle.name in recentlyOpened) {
        recentlyOpened[directoryHandle.name][storageType] = directoryHandle;
      } else {
        recentlyOpened[directoryHandle.name] = {};
        recentlyOpened[directoryHandle.name][storageType] = directoryHandle;
      }
      return recentlyOpened;
    });
  }

  if (storageType === "github") {
    const currentRepo = useStore.getState().currentRepo;
    // get current window URL
    const currentURL = window.location.href;

    await update(`recentlyOpened`, (recentlyOpened) => {
      if (!recentlyOpened) {
        recentlyOpened = {};
      }
      if (currentRepo in recentlyOpened) {
        recentlyOpened[currentRepo][storageType] = currentURL;
      } else {
        recentlyOpened[currentRepo] = {};
        recentlyOpened[currentRepo][storageType] = currentURL;
      }
      return recentlyOpened;
    });
  }
};

export const getOpenProjectsMetadataFromCodeCanvasIndexedDB: () => Promise<
  [string, IRecentlyOpenedEntry][]
> = async () => {
  return await get(`recentlyOpened`).then((recentlyOpened) => {
    if (!recentlyOpened) {
      recentlyOpened = {};
    }
    return Object.entries(recentlyOpened);
  });
};

export const getDirectoryHandleFromCodeCanvasIndexedDB: (
  repoName: any
) => Promise<IRecentlyOpenedEntry> = async (repoName) => {
  return await get(`recentlyOpened`).then((recentlyOpened) => {
    if (!recentlyOpened || !recentlyOpened[repoName]) {
      return null;
    }
    return recentlyOpened[repoName];
  });
};

export const updateRepoDataInCodeCanvasIndexedDB = async () => {
  const repoDataSnapshot = await buildRepoDataSnapshot();
  const simulations = useStore.getState().simulationsState.simulations;
  const cellToPath = useStore.getState().cellToPath;
  await update(`repoData`, (repoKeys) => {
    const repoKey = getRepoKey();
    if (!repoKeys) {
      repoKeys = {};
    }
    repoKeys[repoKey] = {
      repoDataSnapshot: repoDataSnapshot,
      simulations: simulations,
      cellToPath: cellToPath,
    };
    return repoKeys;
  });
};

export const deleteRepoDataInCodeCanvasIndexedDB = async () => {
  await update(`repoData`, (repoKeys) => {
    const repoKey = getRepoKey();
    if (!repoKeys) {
      return {};
    }
    if (repoKeys[repoKey]) {
      delete repoKeys[repoKey];
    }
    return repoKeys;
  });
};

export const getRepoDataFromCodeCanvasIndexedDB = async () => {
  return await get(`repoData`)
    .then((reposData) => {
      const repoKey = getRepoKey();
      if (!reposData || !reposData[repoKey]) {
        return null;
      }
      return reposData[repoKey];
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
};

export function getRepoKey() {
  const currentRepo = useStore.getState().currentRepo;
  const currentBranch = useStore.getState().currentBranch;
  const sessionMode = useStore.getState().session.mode;
  const repoKey =
    sessionMode === "local"
      ? `${currentRepo}-${currentBranch}-local.CodeCanvas`
      : `${currentRepo}-${currentBranch}.CodeCanvas`;
  return repoKey;
}
