import { styled, alpha } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LogoutIcon from "@mui/icons-material/Logout";
import GitHubIcon from "@mui/icons-material/GitHub";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import RadarIcon from "@mui/icons-material/Radar";
import Switch from "@mui/material/Switch";
import { MouseEvent, useCallback, useEffect, useState } from "react";
import {
  deleteDiagramFile,
  invalidateToken,
  saveFileToGithub,
} from "../../../Api/ServerInterface";
import useStore from "../../../Store/Store";
import { clear } from "idb-keyval";
import { removeURLParam } from "../../../utils/URLUtils";
import workflowFile from "../../../utils/CodeCanvasWorkflowFile.txt";
import HelpOutlineSharpIcon from "@mui/icons-material/HelpOutlineSharp";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 15,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.custom.lightGrey,
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.secondary.main,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function OptionsMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [doesCodeCanvasWorkflowFileExist, setDoesCodeCanvasWorkflowFileExist] =
    useState(false);
  const [telemetryData, setTelemetryData] = useState(
    localStorage.getItem("telemetryData") !== "false"
  );

  const {
    isLoggedIn,
    debugMode,
    repoData,
    setLoggedIn,
    postToDrawioWaitForResponse,
    setErrorNotification,
    setSuccessNotification,
  } = useStore((state) => ({
    isLoggedIn: state.session.isLoggedIn,
    debugMode: state.debugMode,
    repoData: state.repoData,
    setLoggedIn: state.setLoggedIn,
    postToDrawioWaitForResponse: state.postToDrawioWaitForResponse,
    setErrorNotification: state.setErrorNotification,
    setSuccessNotification: state.setSuccessNotification,
  }));
  const login = useCallback(async () => {
    const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
    window.location.assign(`${REACT_APP_BACKEND_URL}/auth/github`);
    return;
  }, []);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogin = () => {
    if (!isLoggedIn) {
      sessionStorage.setItem("redirectUrl", window.location.href);
      login();
    }
    handleClose();
  };

  const handleLogout = async () => {
    await invalidateToken();
    localStorage.clear();
    sessionStorage.clear();
    window.location.assign("/");
    setLoggedIn(false);
  };

  const handleResetCache = async () => {
    handleClose();

    // Clear CodeCanvas Cache
    clear();
    // Clear Drawio's Cache
    postToDrawioWaitForResponse({
      action: "RESET_CACHE",
    })
      .then(() => {
        // Reload the page to clear the cache, add reloadTime param to prevent browser's auto caching
        let url = new URL(window.location.href);
        // Remove all params
        url = new URL(url.origin + url.pathname);
        url.searchParams.set("reloadTime", Date.now().toString());
        window.location.href = url.toString();
      })
      .catch(function (error) {
        console.error(error);
        setErrorNotification("Could not reset cache.");
      });
  };

  const handleDeleteDiagramFile = async () => {
    handleClose();

    const response = await deleteDiagramFile(
      useStore.getState().currentRepo,
      useStore.getState().currentBranch,
      useStore.getState().currentRepoMetadata.owner
    );

    if (response.status === 200) {
      handleResetCache();
    } else {
      setErrorNotification("Could not remove diagram file.");
    }
  };

  const handleEnableCodeCanvasScanner = async () => {
    handleClose();

    // saveFileToGithub
    const workflowFileContent = await fetch(workflowFile).then((r) => r.text());
    const response = await saveFileToGithub(
      useStore.getState().currentRepo,
      useStore.getState().currentBranch,
      workflowFileContent,
      useStore.getState().currentRepoMetadata.owner,
      ".github/workflows/codecanvas.yml"
    );

    if (response.status === 200 || response.status === 201) {
      setDoesCodeCanvasWorkflowFileExist(true);
      setSuccessNotification("CodeCanvas Scanner enabled successfully!");
    } else {
      setErrorNotification(
        "Could not enable CodeCanvas Scanner. Please report this issue on Discord."
      );
    }
  };

  const learnMoreAboutTelemetryData = () => {
    window.open(
      `https://www.notion.so/Getting-Started-8aefb25ae4c74c269540ec5be7f014ea?pvs=4#b339910856fb447b941a3ce822040c44`,
      "_blank"
    );
  };

  // Removes the reloadTime param from the URL after the component is mounted
  useEffect(() => {
    removeURLParam(window, "reloadTime");
  }, []);

  // Check if workflow file exists in github repoData
  useEffect(() => {
    if (".github/workflows/codecanvas.yml" in repoData) {
      setDoesCodeCanvasWorkflowFileExist(true);
    } else {
      setDoesCodeCanvasWorkflowFileExist(false);
    }
  }, [repoData]);

  const handleTelemetryData = (e) => {
    if (telemetryData) {
      const TelemetryDialogMessage = () => {
        return (
          <div>
            Telemetry data are debug messages and analytics sent to us to help
            debug and improve the product faster. Learn more about telemetry
            data{" "}
            <a
              href="https://www.notion.so/Getting-Started-8aefb25ae4c74c269540ec5be7f014ea?pvs=4#b339910856fb447b941a3ce822040c44"
              target="_blank"
              rel="noopener noreferrer"
              style={{ fontWeight: "normal" }}
            >
              here
            </a>
            . Are you sure you want to disable telemetry data?
          </div>
        );
      };
      useStore.getState().setDialog("WARNING_DIALOG", {
        message: <TelemetryDialogMessage />,
        button1Function: () => {
          localStorage.setItem("telemetryData", "false");
          setTelemetryData(false);
          window.location.reload();
        },
      });
    } else {
      localStorage.setItem("telemetryData", "true");
      setTelemetryData(true);
      // wait 300ms for the user to notice the switch flipped
      setTimeout(() => {
        window.location.reload();
      }, 300);
    }
  };

  return (
    <div style={{ marginRight: "10px" }}>
      <IconButton
        sx={{
          borderRadius: "30px",
          height: "30px",
        }}
        id="OptionsMenuButton"
        aria-controls={open ? "Options Menu" : undefined}
        onClick={handleClick}
        aria-label="Options Menu"
        component="label"
        color="primary"
      >
        <MoreVertIcon />
      </IconButton>
      <StyledMenu
        id="OptionsMenu"
        MenuListProps={{
          "aria-labelledby": "OptionsMenuButton",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {isLoggedIn ? (
          <MenuItem onClick={handleLogout} disableRipple>
            <LogoutIcon />
            Logout
          </MenuItem>
        ) : (
          <MenuItem onClick={handleLogin} disableRipple>
            <GitHubIcon />
            Login to Github
          </MenuItem>
        )}
        <MenuItem onClick={handleResetCache} disableRipple>
          <RestartAltIcon />
          Reset Cache
        </MenuItem>
        {debugMode && (
          <MenuItem onClick={handleDeleteDiagramFile} disableRipple>
            <DeleteOutlineRoundedIcon />
            Delete Diagram File
          </MenuItem>
        )}
        {!doesCodeCanvasWorkflowFileExist && (
          <MenuItem onClick={handleEnableCodeCanvasScanner} disableRipple>
            <RadarIcon />
            Enable CodeCanvas Scanner
          </MenuItem>
        )}
        <MenuItem
          disableGutters
          disableTouchRipple
          disableRipple
          style={{ paddingLeft: "10px", cursor: "default" }}
        >
          <Switch
            defaultChecked
            checked={telemetryData}
            onClick={handleTelemetryData}
            size="small"
            data-testid="telemetryDataSwitch"
          />
          <div style={{ position: "relative", paddingLeft: "10px" }}>
            Telemetry Data
          </div>
          <HelpOutlineSharpIcon
            className="HelpIcon"
            color="primary"
            onClick={() => learnMoreAboutTelemetryData()}
            style={{ paddingLeft: "10px" }}
          />
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
