import useStore from "../../../../Store/Store";

import React, { useCallback, useEffect, useRef, useState } from "react";
import "./CodeTab.css";
import CircularProgress from "@mui/material/CircularProgress";

import { Menu, MenuItem } from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import {
  newChildCodeCellHandler,
  toggleSingleCellVisibility,
} from "../../../../Store/StoreHandlers";
import { getFileContent } from "../../SourceDocHandlers";
import Highlight, { defaultProps, Prism } from "prism-react-renderer";
import { theme } from "./outputTheme";
import VirtualizedList from "./VirtualizedList";
import UnVirtualizedList from "./UnVirtualizedList";
import { startRecordingActions } from "../../../../Store/UndoManager";
import { logger } from "../../../../utils/Logger";

interface ICodeTab {
  isMaxSD: boolean;
  styleProp?: object;
  className?: string;
}

type Language =
  | "markup"
  | "bash"
  | "clike"
  | "c"
  | "cpp"
  | "css"
  | "javascript"
  | "jsx"
  | "coffeescript"
  | "actionscript"
  | "css-extr"
  | "diff"
  | "git"
  | "go"
  | "graphql"
  | "handlebars"
  | "json"
  | "less"
  | "makefile"
  | "markdown"
  | "objectivec"
  | "ocaml"
  | "python"
  | "reason"
  | "sass"
  | "scss"
  | "sql"
  | "stylus"
  | "tsx"
  | "typescript"
  | "wasm"
  | "yaml";

function getLangFromFilename(filename): Language {
  // Extract the extension from the filename
  const ext = filename?.split(".").pop();
  // Return the language name
  switch (ext) {
    case "js":
      return "javascript";
    case "py":
      return "python";
    case "java":
      return "javascript";
    case "kt":
      return "javascript";
    case "r":
      return "python";
    case "php":
      return "python";
    case "c":
      return "c";
    case "h":
      return "c";
    case "go":
      return "go";
    case "swift":
      return "python";
    case "cs":
      return "objectivec";
    case "json":
      return "json";
    case "ts":
      return "typescript";
    case "tsx":
      return "tsx";
    case "css":
      return "css";
    case "cpp":
      return "cpp";
    case "html":
      return "markup";
    default:
      return "markup";
  }
}

export const CodeTab: React.FC<ICodeTab> = (props: ICodeTab) => {
  const { isMaxSD, className } = props;
  const {
    repoData,
    currentPath,
    setSuccessNotification,
    setSelectedTab,
    selectedTab,
    setCurrentPath,
    selectedCell,
    setDialog,
    cellToPath,
    selectedText,
    setSelectedText,
  } = useStore((state) => ({
    repoData: state.repoData,
    currentPath: state.currentPath,
    setSuccessNotification: state.setSuccessNotification,
    postToDrawioWaitForResponse: state.postToDrawioWaitForResponse,
    setSelectedTab: state.setSelectedTab,
    selectedTab: state.selectedTab,
    setCurrentPath: state.setCurrentPath,
    selectedCell: state.selectedCell,
    setDialog: state.setDialog,
    cellToPath: state.cellToPath,
    selectedText: state.selectedText,
    setSelectedText: state.setSelectedText,
  }));

  const [lang, setLang] = useState<Language>("javascript");
  const childrenPropsInitialState = {
    tokens: [],
    className: "prism-code language-" + lang,
    style: {},
    getLineProps: () => ({}),
    getTokenProps: () => ({}),
  };
  const [childrenProps, setChildrenProps] = useState(childrenPropsInitialState);
  const [highlightInstance, setHighlightInstance] = useState(null);
  const [popUpLoc, setPopUpLoc] = useState(null);
  const [code, setCode] = useState("");
  const [selecting, setSelecting] = useState(false);
  const [selectionEndLine, setSelectionEndLine] = useState(null);
  const [selectionStartLine, setSelectionStartLine] = useState(null);
  const [scrollTop, setScrollTop] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [visibleRows, setVisibleRows] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [USE_VIRTUALIZED_LIST, setUSE_VIRTUALIZED_LIST] = useState(false);
  const loadingTimeout = useRef(null);
  const unvirtualizedListRef = useRef<HTMLPreElement>(null); // The pre tag ref, the scrollable parent
  const virtualizedListRef = useRef<HTMLPreElement>(null); // The pre tag ref, the scrollable parent
  const containerRef = useRef<HTMLDivElement>(null); // .codeContainer ref, highest level
  const userStillInSameFile = useRef(false);
  const lineHeight = 15;
  const DEFAULT_CONTAINER_HEIGHT = 900;
  const codeRenderHandler = useCallback(async () => {
    let renderedCode = "";
    let currentRepoData = repoData[currentPath];
    if (
      currentPath?.includes(".CodeCanvas") ||
      currentPath?.includes(".CodeGram")
    ) {
      renderedCode = currentRepoData?.fileContent?.drawioXML;
    } else if (currentRepoData?.parentPath) {
      renderedCode =
        repoData[currentRepoData?.parentPath]?.fileContent ||
        (await getFileContent(currentRepoData?.parentPath));
    } else if (currentRepoData?.fileContent) {
      renderedCode = currentRepoData?.fileContent;
    } else {
      renderedCode = "Root directory";
    }

    if (
      !(currentRepoData?.type === "blob") &&
      !currentRepoData?.startLine &&
      !currentRepoData?.endLine &&
      selectedTab === 1
    ) {
      setSelectedTab(0);
      return "Unsupported file content!";
    }

    return renderedCode || "Code is loading!";
  }, [repoData, currentPath, setSelectedTab, selectedTab]);
  const smoothScrollTo = (element, target, duration) => {
    const start = element.scrollTop;
    const change = target - start;
    const startTime = performance.now();

    const easeInOutQuad = (t) => {
      t /= duration / 2;
      if (t < 1) return (change / 2) * t * t + start;
      t--;
      return (-change / 2) * (t * (t - 2) - 1) + start;
    };

    const animateScroll = (currentTime) => {
      const timeElapsed = currentTime - startTime;
      const newPosition = easeInOutQuad(timeElapsed);
      element.scrollTop = newPosition;
      if (timeElapsed < duration) {
        window.requestAnimationFrame(animateScroll);
      } else {
        element.scrollTop = target;
      }
    };

    window.requestAnimationFrame(animateScroll);
  };

  const scrollSelectedCodeIntoView = useCallback(() => {
    let newScrollTop = 0;
    const offset = 4 * lineHeight;

    if (USE_VIRTUALIZED_LIST) {
      const startLine = repoData[currentPath]?.startLine
        ? parseInt(repoData[currentPath]?.startLine)
        : null;
      const endLine = repoData[currentPath]?.endLine
        ? parseInt(repoData[currentPath]?.endLine)
        : null;
      const maxScrollTop =
        virtualizedListRef.current?.scrollHeight -
        (containerRef.current.clientHeight || DEFAULT_CONTAINER_HEIGHT);
      const maxNumberOfLinesToDisplay = Math.floor(
        (containerRef.current.clientHeight || DEFAULT_CONTAINER_HEIGHT) /
          lineHeight
      );

      // if current path is a code piece, then scroll to the selected piece of code
      if (startLine) {
        // first line to show such than the selected piece of code is as centered as possible
        let firstLineToShow = Math.max(
          0,
          Math.floor(
            (startLine + endLine) / 2 -
              maxNumberOfLinesToDisplay / 2 +
              (maxNumberOfLinesToDisplay % 2 === 0 ? 1 : 0)
          )
        );
        firstLineToShow = Math.min(startLine, firstLineToShow);

        newScrollTop = Math.min(
          maxScrollTop,
          Math.max(0, firstLineToShow * lineHeight - 1)
        );
        // Handle Beginning of File edge case
        // if end line is less than the current lines of code that can be displayed
        // then we don't need to scroll
        if (
          endLine &&
          endLine <= maxNumberOfLinesToDisplay &&
          startLine <= maxNumberOfLinesToDisplay / 2
        ) {
          newScrollTop = 0;
        }
      }

      // handle case if highlighted code is more than the max number of lines that can be displayed
      if (
        startLine &&
        endLine &&
        endLine - startLine >= maxNumberOfLinesToDisplay
      ) {
        newScrollTop -= offset;
      }

      // if current path is a file, not a line and userStillInSameFile is true, don't scroll
      if (
        currentPath in repoData &&
        !("startLine" in repoData[currentPath]) &&
        userStillInSameFile.current
      ) {
        newScrollTop = virtualizedListRef.current.scrollTop + 1;
        userStillInSameFile.current = false;
      }

      virtualizedListRef.current.scrollTop = newScrollTop;
      setScrollTop(newScrollTop);
    } else {
      const startLineSpan = document.getElementById("start-line-code");
      const endLineSpan = document.getElementById("end-line-code");
      // get the pre tag that contains the code
      let scrollableParent = unvirtualizedListRef.current;
      if (startLineSpan) {
        if (endLineSpan) {
          const rectStart = startLineSpan.getBoundingClientRect();
          const rectEnd = endLineSpan
            ? endLineSpan.getBoundingClientRect()
            : rectStart;

          const parentRect = scrollableParent.getBoundingClientRect();
          const scrollY = scrollableParent.scrollTop;
          // Calculate the available height in the viewport to show the code piece
          const availableHeight = parentRect.height - rectStart.height;

          // Calculate the total height of the code piece from rectStart to rectEnd
          const codePieceHeight = rectEnd.bottom - rectStart.top;

          // Calculate the desired scroll position to show as much of the code piece as possible
          const desiredScrollPosition =
            codePieceHeight > availableHeight
              ? rectStart.top -
                parentRect.top +
                scrollY -
                rectStart.height -
                offset
              : rectStart.top -
                parentRect.top +
                scrollY -
                (parentRect.height - codePieceHeight) / 2;

          // Choose the maximum between the beginning of the file (0) and the calculated desired scroll position
          const scrollTarget = Math.max(0, desiredScrollPosition);

          newScrollTop = scrollTarget;
        } else {
          const parentRect = scrollableParent.getBoundingClientRect();
          const scrollY = scrollableParent.scrollTop;
          const rectStart = startLineSpan.getBoundingClientRect();
          newScrollTop =
            rectStart.top -
            parentRect.top +
            scrollY -
            parentRect.height / 2 +
            rectStart.height / 2;
        }
      }

      if (!scrollableParent) {
        return;
      }
      // if current path is a file, not a line and userStillInSameFile is true, don't scroll
      if (
        currentPath in repoData &&
        !("startLine" in repoData[currentPath]) &&
        userStillInSameFile.current
      ) {
        userStillInSameFile.current = false;
        return;
      }
      smoothScrollTo(scrollableParent, newScrollTop, 300);
      setScrollTop(newScrollTop);
    }
  }, [USE_VIRTUALIZED_LIST, currentPath, repoData]);

  // Update startIndex and endIndex based on scrollTop, containerRef.current.clientHeight
  useEffect(() => {
    if (containerRef.current && childrenProps.tokens) {
      const startIndex = Math.floor(scrollTop / lineHeight);
      const endIndex = Math.min(
        startIndex +
          Math.ceil(
            (containerRef.current.clientHeight || DEFAULT_CONTAINER_HEIGHT) /
              lineHeight
          ) +
          1,
        childrenProps.tokens.length
      );

      setStartIndex(startIndex);

      setVisibleRows(childrenProps.tokens.slice(startIndex, endIndex));
    }
  }, [scrollTop, containerRef, childrenProps.tokens, currentPath, repoData]);

  // 1. Whenever currentPath changes, call get the code as string from remote or local
  useEffect(() => {
    if (selectedTab !== 1 && selectedTab !== 3) return;

    async function renderCode() {
      let codeContent = await codeRenderHandler();
      setCode(codeContent);
    }
    if (
      repoData[currentPath]?.fileName &&
      repoData[currentPath]?.fileName?.length
    ) {
      const lang = getLangFromFilename(repoData[currentPath]?.fileName);
      setLang(lang);
      renderCode();
    } else {
      setCode("");
    }
  }, [currentPath, repoData, isMaxSD, selectedTab, codeRenderHandler]);

  // set loading indicator only if pathChanged === null || code === "Code is loading!"
  useEffect(() => {
    if (selectedTab !== 1 && selectedTab !== 3) return;
    if (code === "Code is loading!") {
      setIsLoading(true);
    } else {
      setIsLoading(false);

      const newLoadingTimeout = setTimeout(() => {
        scrollSelectedCodeIntoView();
        clearTimeout(loadingTimeout.current);
      }, 250);
      loadingTimeout.current = newLoadingTimeout;
    }
  }, [
    code,
    selectedTab,
    loadingTimeout,
    scrollSelectedCodeIntoView,
    virtualizedListRef.current,
  ]);

  // 2. whenever code or lang changes, update highlight instance and call render
  useEffect(() => {
    if (lang && theme) {
      const instance = new Highlight({
        ...defaultProps,
        Prism: Prism,
        language: lang,
        code: code,
        theme: theme,
        children: (highlightData) => {
          setChildrenProps(highlightData as any);
          setUSE_VIRTUALIZED_LIST(
            highlightData.tokens?.length > 10000 || false
          );
          setVisibleRows(null);
          return null;
        },
      });

      setHighlightInstance(instance);
    }
  }, [code, lang, repoData, currentPath]);

  // 3. Whenever highlightInstance changes, call render
  useEffect(() => {
    if (highlightInstance) {
      highlightInstance.render(); // equivalent to return <Highlight/>
    }
  }, [highlightInstance]);

  const codeHighlightHandler = useCallback(
    (lineNumber: number) => {
      let lineStyle = {};
      let path = repoData[currentPath]?.parentPath || currentPath;
      if (
        lineNumber + 1 <= parseInt(repoData[currentPath]?.endLine) &&
        lineNumber + 1 >= parseInt(repoData[currentPath]?.startLine)
      ) {
        lineStyle = {
          // ...lineStyle,
          backgroundColor: "#ffefed",
          fontWeight: "bold",
        };
      } else if (repoData[path]?.children) {
        for (let childPath of repoData[path]?.children) {
          if (
            lineNumber + 1 <= parseInt(repoData[childPath]?.endLine) &&
            lineNumber + 1 >= parseInt(repoData[childPath]?.startLine)
          ) {
            lineStyle = {
              // ...lineStyle,
              cursor: "pointer",
              backgroundColor: "#f1f1f1",
              fontWeight: "bold",
            };
          }
        }
      }

      return lineStyle;
    },
    [repoData, currentPath]
  );

  const selectedLineGetter = useCallback(async () => {
    if (USE_VIRTUALIZED_LIST) {
      let startEndLine = {
        startLine: selectionStartLine,
        endLine: selectionEndLine,
      };
      return startEndLine;
    }
    var boundingClient = window.getSelection().toString();
    // Split the text into an array of lines
    const lines = boundingClient.split("\n");
    if (lines?.length === 1) {
      let startEndLine = {
        startLine: selectionStartLine,
        endLine: selectionStartLine,
      };
      return startEndLine;
    }

    // Extract the first number from the second line
    let firstLineNum = lines[1]?.match(/\d+/) as any;
    if (firstLineNum === null) {
      firstLineNum = null;
    } else {
      firstLineNum = firstLineNum[0];
    }

    // Extract the first number from the last line
    let lastLineNum = lines[lines.length - 1]?.match(/\d+/) as any;
    if (lastLineNum === null) {
      lastLineNum = null;
    } else {
      lastLineNum = lastLineNum[0];
    }

    // Concatenate the end of the string if the first item is alphanumeric
    if (/^[a-zA-Z0-9]+$/.test(lines[0])) {
      let numStr = lines[0].match(/\d+.*/) as any;
      if (numStr !== null) {
        numStr = numStr[0].replace(/\D/g, "");
        if (numStr !== "") {
          firstLineNum = numStr;
          lastLineNum = numStr;
        }
      }
    }

    let startEndLine = {
      startLine: (parseInt(firstLineNum) - 1).toString(),
      endLine: lastLineNum,
    };
    return startEndLine;
  }, [selectionStartLine, selectionEndLine, USE_VIRTUALIZED_LIST]);

  const childCellCreationHandler = useCallback(async () => {
    await startRecordingActions("ADD_CODE_NODE");
    let filePath = repoData[currentPath]?.parentPath || currentPath;
    let file = repoData[filePath];
    let repoItemData = repoData[file.path];

    // selectedCell either key to cellData or
    // must get linked path from cellToPath
    let cellItemData =
      repoData[selectedCell] || repoData[cellToPath[selectedCell]];

    // if selected cell is already child of parent
    // or parentCell is selected - can add a new code cell
    if (
      repoData[selectedCell]?.parentPath === file.path ||
      repoData[cellToPath[selectedCell]]?.path === file.path
    ) {
      let startEndLine = await selectedLineGetter();
      await newChildCodeCellHandler(
        startEndLine?.startLine,
        startEndLine?.endLine
      );
    }
    // if cell is linked
    else if (
      selectedCell in cellToPath ||
      (repoData[selectedCell]?.cellId && repoData[selectedCell]?.parentPath)
    ) {
      const dialogMessage = `The selected cell: "${
        cellItemData.cellId
      }" is already 
          linked to the file: "${
            cellItemData.fileName || "Unititled Cell"
          }", please unselect the cell or select 
          an un-linked cell to create a cell linked to file: "${
            repoItemData.fileName
          }"`;
      setDialog("WARNING_DIALOG", {
        message: dialogMessage,
      });
    }

    // both items exist in repoData so must conglomerate data
    // but cell is not linked to a file
    // ask to over write cell with repoItem data
    else if (selectedCell && !(selectedCell in cellToPath)) {
      let startEndLine = await selectedLineGetter();
      if (repoItemData?.wiki?.length && cellItemData?.wiki?.length) {
        const dialogMessage = `There is wiki data in both artifacts!
             Choose which artifact to overwrite.`;
        setDialog("OVERWRITE_DIALOG", {
          message: dialogMessage,
          button2Function: async () => {
            return await newChildCodeCellHandler(
              startEndLine?.startLine,
              startEndLine?.endLine,
              true
            );
          },
        });
      } else {
        await newChildCodeCellHandler(
          startEndLine?.startLine,
          startEndLine?.endLine,
          true
        );
      }
    }
    // TODO: currently allowing parent file to be added in retro,
    // later will add parent file and child at same time so
    // child cell cannot exist without parent already existing
    else if (!selectedCell) {
      // const dialogMessage = `The selected file: "${file.fileName}", either does not have a cell on the graph,
      // or it is not selected. Please select the parent cell to add a code block into it!"`;
      // setDialog("WARNING_DIALOG", {
      //   message: dialogMessage,
      // });
      let startEndLine = await selectedLineGetter();
      await newChildCodeCellHandler(
        startEndLine?.startLine,
        startEndLine?.endLine
      );
    }
    if (useStore.getState().recordStateChange === "START") {
      logger.error(
        "reached the end of childCellCreationHandler without saving recording"
      );
    }
    setSelectionStartLine(null);
  }, [
    selectedLineGetter,
    selectedCell,
    cellToPath,
    repoData,
    setDialog,
    currentPath,
  ]);

  const startLineEndLineIdTagger = (lineNumber) => {
    let id = "";
    if (lineNumber === parseInt(repoData[currentPath]?.startLine)) {
      id = "start-line-code";
    } else if (lineNumber === parseInt(repoData[currentPath]?.endLine)) {
      id = "end-line-code";
    }
    return id;
  };

  const lineClickHandler = useCallback(
    (lineNumber: number) => {
      let path = repoData[currentPath]?.parentPath || currentPath;
      if (repoData[path]?.children) {
        for (let childPath of repoData[path]?.children) {
          if (
            lineNumber + 1 <= parseInt(repoData[childPath]?.endLine) &&
            lineNumber + 1 >= parseInt(repoData[childPath]?.startLine)
          ) {
            setCurrentPath(childPath);
            toggleSingleCellVisibility(repoData[childPath].cellId, true);
            return;
          }
        }
      }
      setCurrentPath(path);
    },
    [repoData, currentPath, setCurrentPath]
  );

  const handlePopUpClose = () => {
    setSelectedText(null);
    setPopUpLoc(null);
    setSelecting(false);
    setSelectionStartLine(null);
    setSelectionEndLine(null);
    setSelectedText("");
    setSelecting(false);
  };

  return (
    <div
      className={className || "codeContainer"}
      style={!className ? { height: isMaxSD ? "80vh" : "60vh" } : {}}
      ref={containerRef}
    >
      {isLoading && (
        <div className="circularProgressContainer">
          <CircularProgress />
        </div>
      )}
      {popUpLoc && selectedText && (
        <Menu
          variant="menu"
          disableAutoFocus
          disableAutoFocusItem
          autoFocus={false}
          open={selectedText ? true : false}
          anchorReference="anchorPosition"
          anchorPosition={{
            top: popUpLoc.y,
            left: popUpLoc.x + 20,
          }}
          sx={{ boxShadow: 1 }}
          onClose={handlePopUpClose}
        >
          <MenuItem dense onClick={childCellCreationHandler}>
            <AddCircleOutlineOutlinedIcon />
          </MenuItem>
          <MenuItem
            dense
            onClick={() => {
              navigator.clipboard.writeText(selectedText);
              setSelectedText(null);
              setSuccessNotification(
                `Copied text from ${
                  repoData[currentPath]?.name === repoData[currentPath]?.cellId
                    ? "Untitled Node"
                    : repoData[currentPath]?.name
                } to clipboard!`
              );
              setSelectionStartLine(null);
            }}
          >
            <ContentCopyOutlinedIcon />
          </MenuItem>
        </Menu>
      )}
      {USE_VIRTUALIZED_LIST && (
        <VirtualizedList
          virtualizedListRef={virtualizedListRef}
          className={childrenProps.className}
          style={childrenProps.style}
          tokens={childrenProps.tokens}
          getLineProps={childrenProps.getLineProps}
          getTokenProps={childrenProps.getTokenProps}
          startLineEndLineIdTagger={startLineEndLineIdTagger}
          lineClickHandler={lineClickHandler}
          codeHighlightHandler={codeHighlightHandler}
          setScrollTop={setScrollTop}
          containerHeight={
            containerRef.current?.clientHeight || DEFAULT_CONTAINER_HEIGHT
          }
          itemHeight={lineHeight}
          visibleRows={visibleRows}
          startIndex={startIndex}
          setSelectedText={setSelectedText}
          popUpLoc={popUpLoc}
          setPopUpLoc={setPopUpLoc}
          selecting={selecting}
          selectionStartLine={selectionStartLine}
          selectionEndLine={selectionEndLine}
          setSelecting={setSelecting}
          setSelectionStartLine={setSelectionStartLine}
          setSelectionEndLine={setSelectionEndLine}
          userStillInSameFile={userStillInSameFile}
        />
      )}

      {!USE_VIRTUALIZED_LIST && (
        <UnVirtualizedList
          virtualizedListRef={unvirtualizedListRef}
          className={childrenProps.className}
          style={childrenProps.style}
          tokens={childrenProps.tokens}
          getLineProps={childrenProps.getLineProps}
          getTokenProps={childrenProps.getTokenProps}
          startLineEndLineIdTagger={startLineEndLineIdTagger}
          lineClickHandler={lineClickHandler}
          setSelectionStartLine={setSelectionStartLine}
          codeHighlightHandler={codeHighlightHandler}
          selectedText={selectedText}
          setSelectedText={setSelectedText}
          setPopUpLoc={setPopUpLoc}
          userStillInSameFile={userStillInSameFile}
        />
      )}
    </div>
  );
};
