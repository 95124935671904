import "./DataSourceToggle.css";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Tooltip, Typography } from "@mui/material";
import githubToggleIcon from "../../../Media/githubToggleIcon.svg";
import localStorageToggleIcon from "../../../Media/localStorageToggleIcon.svg";
import useStore from "../../../Store/Store";
import { useCallback, useEffect, useState } from "react";
import { openDirectory } from "../../../utils/LocalFileController/LocalFileController";
import { getDirectoryHandleFromCodeCanvasIndexedDB } from "../../../utils/CodeCanvasIndexedDB";
import { verifyPermission } from "../../../utils/LocalFileController/LocalFileHelpers";
import { setURLParam } from "../../../utils/URLUtils";
import { IRecentlyOpenedEntry } from "../../LandingPage/RecentFilesDropdown";

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 52,
  height: 28,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url(${localStorageToggleIcon})`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.custom.darkGrey,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.custom.lightGrey,
    width: 23,
    height: 23,
    "&:before": {
      content: "''",
      position: "absolute",
      width: 23,
      height: 23,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url(${githubToggleIcon})`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.custom.lightGrey,
    borderRadius: 20 / 2,
  },
}));

const sessionsToUpperCase = {
  github: "GitHub",
  local: "Local",
  unauthenticatedGithub: "",
};

export default function DataSourceToggle() {
  const {
    sessionMode,
    currentRepo,
    currentBranch,
    isLoggedIn,
    setSessionMode,
    setErrorNotification,
  } = useStore((state) => ({
    sessionMode: state.session.mode,
    currentRepo: state.currentRepo,
    currentBranch: state.currentBranch,
    isLoggedIn: state.session.isLoggedIn,
    setSessionMode: state.setSessionMode,
    setErrorNotification: state.setErrorNotification,
  }));
  const [tooltipText, setTooltipText] = useState(
    "Switch to local storage mode"
  );

  const onToggleClick = () => {
    if (sessionMode === "github") {
      handleSwitchToLocal();
    } else {
      handleSwitchToGithub();
    }
  };

  const login = useCallback(async () => {
    const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
    window.location.assign(`${REACT_APP_BACKEND_URL}/auth/github`);
    return;
  }, []);

  const handleSwitchToLocal = async () => {
    setSessionMode("local");
    setURLParam(window, "session", "local");
    try {
      useStore.getState().setIsUserChoosingDirectory(true);
      // Check if directory handle exists in CodeCanvas IndexedDB (i.e. recently opened)
      const recentlyOpenedEntry: IRecentlyOpenedEntry =
        await getDirectoryHandleFromCodeCanvasIndexedDB(currentRepo);

      const recentlyOpenedHandle =
        recentlyOpenedEntry?.local as FileSystemDirectoryHandle;
      if (recentlyOpenedHandle) {
        verifyPermission(recentlyOpenedHandle, true)
          .then(async (permission) => {
            if (permission === false) {
              console.error("No permission to access local directory");
              setErrorNotification("No permission to access local directory");
              return;
            }

            // set directory handle for local file
            const setLocalDirectoryMetaData =
              useStore.getState().setLocalDirectoryMetaData;
            setLocalDirectoryMetaData({
              handle: recentlyOpenedHandle,
            });
            await openDirectory(true, recentlyOpenedHandle);
          })
          .catch((err) => {
            throw err;
          });
      } else {
        // If not, open directory picker
        await openDirectory(true);
      }
    } catch (error) {
      useStore.getState().setIsUserChoosingDirectory(false);
      setSessionMode("github");
      setURLParam(window, "session", "github");
      console.error(error);
      if (error.name !== "AbortError") {
        setErrorNotification("Could not switch to local mode");
      }
    }
  };

  const handleSwitchToGithub = () => {
    try {
      if (!isLoggedIn) {
        login();
      }
      setSessionMode("github");
      setURLParam(window, "session", "github");

      const branchesList = useStore.getState().branchesList;
      const currentBranch = useStore.getState().currentBranch;

      // If currentBranch is not in branchesList, notify the user to publish their branch
      if (
        !branchesList ||
        !branchesList
          .map((branchObj: Branch) => branchObj.name)
          .includes(currentBranch)
      ) {
        throw new Error(
          `Branch "${currentBranch}" not found in remote repository. Please publish your branch to GitHub and refresh the page.`
        );
      }

      // reload page with the new URL params
      window.location.reload();
    } catch (error) {
      console.error(error);
      setErrorNotification(error.message);
      setSessionMode("local");
      setURLParam(window, "session", "local");
    }
  };

  // Change tooltip test based on sessionMode
  useEffect(() => {
    if (sessionMode === "github") {
      setTooltipText(
        `Check local working directory diagram for ${currentRepo}/${currentBranch}`
      );
    } else if (sessionMode === "unauthenticatedGithub") {
      setTooltipText(
        `Log in with github to enable switching between local and remote repository diagrams using this toggle.`
      );
    } else {
      setTooltipText(
        `Check remote repository diagram for ${currentRepo}/${currentBranch}`
      );
    }
  }, [sessionMode, currentRepo, currentBranch, isLoggedIn]);

  return (
    currentBranch && (
      <Tooltip title={tooltipText}>
        <FormControlLabel
          className="ToggleWrapper"
          control={
            <MaterialUISwitch
              sx={{ m: 0.5 }}
              checked={sessionMode === "local"}
              onClick={onToggleClick}
              disabled={sessionMode === "unauthenticatedGithub" && !isLoggedIn}
            />
          }
          label={
            <Typography style={{ fontSize: 14, color: "#FFAEA6" }}>
              {sessionMode in sessionsToUpperCase
                ? sessionsToUpperCase[sessionMode]
                : ""}
            </Typography>
          }
        />
      </Tooltip>
    )
  );
}
