import useStore from "../Store/Store";

export async function perform(method, resource, data, customReqParams = null) {
  const BASE_URI = process.env.REACT_APP_BACKEND_URL;
  const accessToken = localStorage.getItem("accessToken");
  try {
    let reqParams;
    if (method === "post" || method === "put") {
      const reqData = {
        ...data,
        isLoggedIn: useStore.getState().session.isLoggedIn,
      };
      reqParams = {
        method: method,
        credentials: "include",
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(reqData),
      };
    } else {
      reqParams = {
        method: method,
        credentials: "include",
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };
    }

    if (customReqParams) {
      reqParams = {
        ...reqParams,
        ...customReqParams,
      };
    }

    let res = await fetch(`${BASE_URI}${resource}`, reqParams);

    if (res.status !== 200) {
      throw new Error("authentication has been failed!");
    }
    res = await res.json();
    return res;
  } catch (e) {
    console.error(e);
  }
}
