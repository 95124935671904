import axios from "axios";
import { perform } from "./ApiClient";
import { downloadURLResponse } from "./Responses";
import useStore from "../Store/Store";

export async function getUser(user) {
  return await perform("get", `/auth/login/success`, null, user);
}

export async function getRepos() {
  const data = {};
  return await perform("post", `/getReposList`, data);
}

export async function getDefaultBranch(repo, owner) {
  const data = {
    repo: repo,
    owner: owner,
  };
  return await perform("post", `/getdefaultbranch`, data);
}

export async function getBranches(repo, owner) {
  const data = {
    repo: repo,
    owner: owner,
  };
  return await perform("post", `/getbranches`, data);
}

export async function saveFileToGithub(repo, branch, content, owner, path) {
  const data = {
    repo: repo,
    branch: branch,
    content: content,
    owner: owner,
    path: path,
  };

  return await perform("put", `/save`, data);
}

export async function deleteDiagramFile(repo, branch, owner) {
  const data = {
    repo: repo,
    branch: branch,
    owner: owner,
  };

  return await perform("put", `/deletediagramfile`, data);
}

export async function getRepoData(repo, branch, owner = "sameUser") {
  const data = {
    owner: owner && owner !== "sameUser" ? owner : "sameUser",
    repo: repo,
    branch: branch,
    isLoggedIn: useStore.getState().session.isLoggedIn,
  };
  return await perform("post", `/getrepodata`, data);
}

export async function invalidateToken() {
  const result = await perform("get", `/auth/logout`, null);
  return result;
}

export async function getFileDownloadURL(url, path, branch) {
  const data = {
    url: url,
    path: path,
    branch: branch,
  };
  return await perform("post", `/getFileDownloadURL`, data);
}

export async function getCheckRunResult(repo, owner, checkRunId) {
  const data = {
    repo: repo,
    owner: owner,
    checkRunId: checkRunId,
  };
  return await perform("post", `/getCheckRunResult`, data);
}

export async function getFileContentFromGithub(url, path, branch) {
  const data = {
    url: url,
    path: path,
    branch: branch,
  };
  let fileDownloadURLResponse: downloadURLResponse = await perform(
    "post",
    `/getFileDownloadURL`,
    data
  );
  const downloadURL = fileDownloadURLResponse.download_url;
  return await axios
    .get(downloadURL)
    .then(function (response) {
      let fileContent = response?.data;
      if (!fileContent || fileContent.length === 0) {
        throw new Error("Could not retrieve file content!");
      }
      return fileContent;
    })
    .catch(function (error) {
      const setErrorNotification = useStore.getState().setErrorNotification;
      setErrorNotification(`Error loading file at ${path} `);
      console.error("Unable to load file content. Error: ", error);
    });
}
