import { theme } from "../../Themes/Themes";
import "./RecentFilesDropdown.css";
import { Typography } from "@mui/material";

export function AcknowledgementStatement() {
  return (
    <div className="AcknowledgementStatement">
      <Typography style={{ fontSize: "13px" }} color={theme.custom.darkGrey}>
        By using Code Canvas, you agree to our{" "}
        <a
          href="https://pie-crepe-38f.notion.site/CodeCanvas-Terms-and-Conditions-ef6b3e7a3ee54da3b363c48112062bce?pvs=4"
          target="_blank"
          rel="noopener noreferrer"
          style={{ fontWeight: "normal", color: theme.custom.pink }}
        >
          terms of service
        </a>{" "}
        and{" "}
        <a
          href="https://pie-crepe-38f.notion.site/CodeCanvas-Privacy-Policy-5466713f74724c9ab0da9a1d669ab379?pvs=4"
          target="_blank"
          rel="noopener noreferrer"
          style={{ fontWeight: "normal", color: theme.custom.pink }}
        >
          privacy policy
        </a>
      </Typography>
    </div>
  );
}
