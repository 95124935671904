import React, { useCallback, useEffect, useState } from "react";
import "./Header.css";
import { getUser } from "../../Api/ServerInterface";
import useStore from "../../Store/Store";
import { RepoDropdown } from "./RepoDropdown";
import { BranchesDropdown } from "./BranchDropdown";
import HelpOutlineSharpIcon from "@mui/icons-material/HelpOutlineSharp";
import { UserInfoResponse } from "../../Api/Responses";
import FileMenu from "./FileMenu/FileMenu";
import DataSourceToggle from "./DataSourceToggle/DataSourceToggle";
import OptionsMenu from "./OptionsMenu/OptionsMenu";
import StatusBar from "./StatusBar/StatusBar";
import DiscordButtonSVG from "../../Media/DiscordLogo";
import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { theme } from "../../Themes/Themes";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import { getURLParam } from "../../utils/URLUtils";
import * as Sentry from "@sentry/react";
import { logger } from "../../utils/Logger";


export const Header: React.FC = () => {
  const {
    sessionMode,
    undoState,
    redoState,
    URLParamParsingDone,
    setLoggedIn,
    setUsername,
    setDialog,
  } = useStore((state) => ({
    sessionMode: state.session.mode,
    undoState: state.undoRedoState.undo,
    redoState: state.undoRedoState.redo,
    URLParamParsingDone: state.URLParamParsingDone,
    setLoggedIn: state.setLoggedIn,
    setUsername: state.setUsername,
    setDialog: state.setDialog,
  }));

  const showStayTuned = !localStorage.getItem("emailEntered");
  
  const goNotion = () => {
    window.location.assign(
      `https://pie-crepe-38f.notion.site/Documentation-cf20cbdf134247cb93bf6366d9055076`
    );
  };

  const handleDiscordClick = () => {
    window.open("https://discord.gg/t3ezMyMPqr", "_blank").focus();
  };

  const handleUndoRedoClick = async (newValue) => {
    await useStore.getState().postToDrawioWaitForResponse({
      action: "UNDO_MANAGER_OPERATION",
      data: { undoManagerOperationName: newValue },
    });
  };

  const login = useCallback(async () => {
    const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
    window.location.assign(`${REACT_APP_BACKEND_URL}/auth/github`);
    return;
  }, []);

  const showReportDialog = () => {
    const error = new Error("[USER FEEDBACK]");
    Sentry.captureException(error);
  };

  // STEP 1: Retrieves user details once authenticated
  useEffect(() => {
    const isTestMode = localStorage.getItem("testMode");
    if (URLParamParsingDone && sessionMode === "github" && !isTestMode) {
      try {
        const username = localStorage.getItem("username");
        // checking for repo in the URL params is a hacky way to force login when user is redirected to codecanvas with url params
        const repoURLParam = getURLParam(window, "repo");
        if (!username && repoURLParam) {
          // store current url in local storage
          sessionStorage.setItem("redirectUrl", window.location.href);
          login();
        }
        getUser({
          username: username,
        }).then((resp: any) => {
          const userDetails = resp;
          if (!userDetails?.user) {
            logger.info(
              "User not logged in, username access token not retrieved."
            );
            setLoggedIn(false);
            return;
          }
          localStorage.setItem("accessToken", userDetails.user.access_token);
          localStorage.setItem("username", userDetails.user.username);
          setUsername(userDetails.user.username);
          setLoggedIn(true);
          if (!userDetails.isAppInstalled) {
            window.location.assign(
              "https://github.com/apps/codecanvas-app/installations/new"
            );
          }
          const redirectURL = sessionStorage.getItem("redirectUrl");
          if (redirectURL) {
            // clear redirect url from local storage
            sessionStorage.removeItem("redirectUrl");
            // redirect to the url
            window.location.assign(redirectURL);
          }
        });
      } catch (err) {
        console.error(err);
        setLoggedIn(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [URLParamParsingDone]);

  return (
    <header className="Header">
      <div className="LogoWrapper">
        <h2 className="Logo">CodeCanvas</h2>
        <Typography
          variant="caption"
          color={theme.custom.lightGrey}
          className="Subheader"
        >
          This is a Beta version, if you are experiencing bugs or have feedback
          click{" "}
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              return showReportDialog();
            }}
          >
            here
          </a>
          .
        </Typography>
        {showStayTuned && (
          <Typography
            variant="caption"
            color={theme.custom.lightGrey}
            className="StayTunedHeader"
          >
            Stay tuned for the{" "}
            <a
              className="StayTunedPulse"
              onClick={(e) => {
                e.preventDefault();
                setDialog("EMAIL_DIALOG")
              }}
            >
              <b>official release!</b>
            </a>
          </Typography>
        )}
      </div>
      <div className="RightSectionOfHeader">
        <ToggleButtonGroup
          color="primary"
          value={"undo"}
          exclusive
          style={{
            height: "17px",
            borderRadius: "20px",
            width: "34px",
            position: "relative",
            right: "90px",
            top: "-3px",
          }}
          // change border radio to 30px
          sx={{
            "& .MuiToggleButton-root": {
              borderRadius: "17px",
              border: `1px solid ${theme.custom.lighterPink}`,
              color: theme.custom.lighterPink,
              "&.Mui-disabled": {
                opacity: 0.7,
                border: `1px solid ${theme.custom.lighterPink}`,
              },
              "&.Mui-selected": {
                backgroundColor: "transparent",
              },
            },
          }}
          onChange={async (event, newValue) => {
            return await handleUndoRedoClick(newValue);
          }}
          aria-label="Platform"
        >
          <ToggleButton
            value="UNDO"
            selected
            disabled={!undoState}
            id={"undo-button"}
          >
            <UndoIcon style={{ height: "17px", width: "17px" }} />
          </ToggleButton>
          <ToggleButton
            value="REDO"
            selected
            disabled={!redoState}
            id={"redo-button"}
          >
            <RedoIcon style={{ height: "17px", width: "17px" }} />
          </ToggleButton>
        </ToggleButtonGroup>
        <DataSourceToggle />
        <StatusBar />
        <RepoDropdown />
        <BranchesDropdown />
        <FileMenu />
        <OptionsMenu />
        <div className="DiscordButtonWrapper" onClick={handleDiscordClick}>
          <DiscordButtonSVG />
        </div>
        <HelpOutlineSharpIcon
          className="HelpIcon"
          color="primary"
          onClick={() => goNotion()}
        />
      </div>
    </header>
  );
};
