import axios from "axios";
import useStore from "../Store/Store";
import { downloadURLResponse } from "./Responses";
import { getCheckRunResult, getFileDownloadURL } from "./ServerInterface";
import landingPageXML from "../TemplateDiagrams/LandingPage.js";
import {
  doesFileExist,
  loadDiagramFileFromLocal,
  openFile,
} from "../utils/LocalFileController/LocalFileController";
import {
  buildRepoDataSnapshot,
  mergeSavedDataAndRepoData,
} from "../utils/RepoDataAction";
import {
  getRepoDataFromCodeCanvasIndexedDB,
  updateRepoDataInCodeCanvasIndexedDB,
} from "../utils/CodeCanvasIndexedDB";
import {
  compareRefCellToPathToCached,
  compareRepoDataWikis,
  sendShadowDataToDrawio,
  compareRefSimulationsToCached,
} from "../Components/Header/StatusBar/StatusBarUtils";
import { toggleCellsVisibility } from "../Components/SourceDoc/TabViews/SimulationsTab/SimulationUtils";
import { logger } from "../utils/Logger";
import { getURLParam } from "../utils/URLUtils";

export const loadDiagram = async () => {
  const setErrorNotification = useStore.getState().setErrorNotification;
  const currentRepo = useStore.getState().currentRepo;
  const currentBranch = useStore.getState().currentBranch;
  const postToDrawioWaitForResponse =
    useStore.getState().postToDrawioWaitForResponse;
  const sessionMode = useStore.getState().session.mode;
  const isLoggedIn = useStore.getState().session.isLoggedIn;
  const repoData = useStore.getState().repoData;

  try {
    if (!currentBranch || (sessionMode === "github" && !isLoggedIn)) {
      await resetToLandingPage();
    } else {
      const fileName =
        sessionMode === "local"
          ? `${currentRepo}-${currentBranch}-local.drawio`
          : `${currentRepo}-${currentBranch}.drawio`;

      const drawioResponse = await postToDrawioWaitForResponse({
        action: "OPEN_DIAGRAM_FROM_BROWSER_STORAGE",
        data: { key: fileName },
      });

      // if a new diagram has been created indexedDB that means user doesn't have uncommitted changes,
      // therefore, if there is a remote CodeCanvas file in repo, load remote file
      if (drawioResponse?.data?.newDiagramCreated) {
        if (sessionMode === "local") {
          loadDiagramFileFromLocal();
        } else {
          loadDiagramFromRemoteRepository();
        }
      } else {
        /**
         Once where inside this else statement, we know that the diagram is already in drawio's indexedDB
         Excute the following in parallel:
         1. Load reference diagram file (the content saved on disk or in remote) to be used later for comparison
         to check if there are any uncommitted/saved changes       
         2. Check CodeCanvas indexedDB for cached repo data
        */

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, cachedRepoMetaData] = await Promise.all([
          loadReferenceDiagramFile(),
          getRepoDataFromCodeCanvasIndexedDB(),
        ]);

        const cachedSimulations = cachedRepoMetaData?.simulations;
        const cachedCellToPath = cachedRepoMetaData?.cellToPath;
        const cachedRepoData = cachedRepoMetaData?.repoDataSnapshot;

        if (
          Object.keys(repoData).length !== 0 &&
          cachedRepoData &&
          Object.keys(cachedRepoData).length !== 0
        ) {
          const newRepoData = mergeSavedDataAndRepoData(
            cachedRepoData,
            repoData
          );
          useStore.getState().setRepoData(newRepoData, false);
          compareRepoDataWikis(cachedRepoData);
        }
        if (cachedCellToPath && Object.keys(cachedCellToPath).length !== 0) {
          useStore.getState().setCellToPath(cachedCellToPath);
          compareRefCellToPathToCached(cachedCellToPath);
        }
        if (cachedSimulations && Object.keys(cachedSimulations).length !== 0) {
          useStore.getState().setSimulations(cachedSimulations);
          compareRefSimulationsToCached(cachedSimulations);
        } else {
          useStore.getState().setSimulations({});
        }
        // once cachedRepoData resolves, even if it's undefined, we can then hide hidden cells
        // first make sure cachedRepoData is no longer a promise
        if (!(cachedRepoData instanceof Promise)) {
          toggleCellsVisibility(false);
        }
        useStore
          .getState()
          .setSuccessNotification(`Diagram restored from local storage!`);

        const resp = await useStore.getState().postToDrawioWaitForResponse({
          action: "UNDO_MANAGER_OPERATION",
          data: { undoManagerOperationName: "CLEAR_HISTORY" },
        });
        if (resp.status === "SUCCESS") {
          logger.debug.info("Successfully cleared history");
        }
      }

      // Check if there is a checkRunId in the URL, if so call the backend to retrieve  and store checkRunResult
      const checkRunId = getURLParam(window, "checkRunId");
      if (checkRunId) {
        const checkRunResult = await getCheckRunResult(
          currentRepo,
          useStore.getState().currentRepoMetadata.owner,
          checkRunId
        );
        if (checkRunResult) {
          const checkRunImpactedCells: CheckRunImpactedCell[] = JSON.parse(
            (checkRunResult as any)?.text
          )?.files as CheckRunImpactedCell[];
          useStore.getState().setCheckRunImpactedCells(checkRunImpactedCells);
        }
      }
    }
  } catch (e) {
    console.error(e);
    setErrorNotification(`Error loading diagram!`);
  } finally {
    useStore.getState().setDoLoadDiagram(false);
  }
};

const getDiagramFileContentFromGithub = async (
  isSetLoadingNotification = true
) => {
  const repoData = useStore.getState().repoData;
  const currentBranch = useStore.getState().currentBranch;
  const currentRepo = useStore.getState().currentRepo;

  if (!repoData || Object.keys(repoData).length === 0) {
    return;
  }
  let diagramFile = null;
  for (const [key, value] of Object.entries(repoData)) {
    if (key === `${currentRepo}.CodeCanvas`) {
      diagramFile = value;
      break;
    }
  }

  if (!diagramFile || !diagramFile.url || diagramFile.url === undefined) {
    useStore.getState().setIsNewDiagramFileCreated(true);
    return null;
  }
  useStore.getState().setIsNewDiagramFileCreated(false);
  const fileDownloadURLResponse: downloadURLResponse = await getFileDownloadURL(
    diagramFile.url,
    diagramFile.path,
    currentBranch
  );
  const downloadURL = fileDownloadURLResponse.download_url;
  const download_url_with_branch =
    fileDownloadURLResponse?.download_url_with_branch;
  useStore.getState().setDiagramData({
    fileName: diagramFile.fileName,
    fileURL: downloadURL,
  });
  if (isSetLoadingNotification) {
    const setLoadingNotification = useStore.getState().setLoadingNotification;
    setLoadingNotification(`Loading Diagram: ${diagramFile.fileName}`);
  }

  let fileContent;
  try {
    fileContent = await axios.get(downloadURL);
  } catch (error) {
    if (error.response.status === 404 && download_url_with_branch) {
      fileContent = await axios.get(download_url_with_branch);
    } else {
      throw error;
    }
  }
  return fileContent?.data;
};

export const loadDiagramFromRemoteRepository = async () => {
  const setLoadingNotification = useStore.getState().setLoadingNotification;
  const setDoLoadDiagram = useStore.getState().setDoLoadDiagram;
  const fileContent = await getDiagramFileContentFromGithub();
  const fileName = useStore.getState().diagramData.fileName;
  const downloadURL = useStore.getState().diagramData.fileURL;

  try {
    if (!fileContent) {
      return;
    }
    useStore.getState().setReferenceDiagramFile(fileContent);
    const diagramData = fileContent?.drawioXML;
    // set combined repo data objects as repoData
    let mergedRepoData = mergeSavedDataAndRepoData(
      fileContent?.repoData,
      useStore.getState().repoData
    );
    useStore.getState().setRepoData(mergedRepoData, false);
    useStore.getState().setSimulations(fileContent?.simulations || {});
    useStore.getState().setCellToPath(fileContent?.cellToPath);

    updateRepoDataInCodeCanvasIndexedDB();
    if (!diagramData || diagramData.length === 0) {
      throw new Error("Could not retrieve diagram data!");
    }
    useStore.getState().setModifiedWikis("CLEAR", {});
    const postToDrawioWaitForResponse =
      useStore.getState().postToDrawioWaitForResponse;

    const drawioResponse = await postToDrawioWaitForResponse({
      action: "UPDATE_DIAGRAM",
      options: { loadNewDiagram: true },
      data: { xml: diagramData, fileURL: downloadURL },
    });

    if (drawioResponse.status === "SUCCESS") {
      const setSuccessNotification = useStore.getState().setSuccessNotification;
      setSuccessNotification(`Successfully loaded ${fileName}`);
      loadReferenceDiagramFile();
      toggleCellsVisibility(false);
    } else {
      throw new Error(JSON.stringify(drawioResponse));
    }
  } catch (e) {
    const setErrorNotification = useStore.getState().setErrorNotification;
    setErrorNotification(`Error loading ${fileName} `);
    console.error("loadDiagram: unable to load diagram. Error: ", e);
  } finally {
    setLoadingNotification("");
    setDoLoadDiagram(false);
  }
};

const resetToLandingPage = async () => {
  try {
    const postToDrawioWaitForResponse =
      useStore.getState().postToDrawioWaitForResponse;
    await postToDrawioWaitForResponse({
      action: "OPEN_DIAGRAM_FROM_BROWSER_STORAGE",
      data: { key: "LandingPage.drawio" },
    });

    await postToDrawioWaitForResponse({
      action: "UPDATE_DIAGRAM",
      options: { loadNewDiagram: true },
      data: { xml: landingPageXML },
    });
  } catch (e) {
    throw e;
  }
};

export const loadReferenceDiagramFile = async () => {
  try {
    const sessionMode = useStore.getState().session.mode;
    const currentRepo = useStore.getState().currentRepo;
    if (sessionMode === "local") {
      if (!(await doesFileExist(`${currentRepo}.CodeCanvas`))) {
        useStore.getState().setIsNewDiagramFileCreated(true);
        sendShadowDataToDrawio(true);
        return;
      }
      useStore.getState().setIsNewDiagramFileCreated(false);

      const { fileContent } = await openFile(
        `${currentRepo}.CodeCanvas`,
        null,
        false
      );
      const parsedContent = await JSON.parse(fileContent);
      useStore.getState().setReferenceDiagramFile(parsedContent);
    } else if (
      sessionMode === "github" ||
      sessionMode === "unauthenticatedGithub"
    ) {
      const fileContent = await getDiagramFileContentFromGithub(false);
      if (!fileContent) {
        sendShadowDataToDrawio(true);
        return;
      }
      if (!fileContent["simulations"]) {
        fileContent["simulations"] = {};
      }
      useStore.getState().setReferenceDiagramFile(fileContent);
    }
    compareRepoDataWikis(await buildRepoDataSnapshot());
    const simulations = useStore.getState().simulationsState.simulations;
    compareRefSimulationsToCached(simulations);
    const cellToPath = useStore.getState().cellToPath;
    compareRefCellToPathToCached(cellToPath);
    sendShadowDataToDrawio();
  } catch (e) {
    throw e;
  }
};
